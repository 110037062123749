<template>
  <div>
    <button
        class="review-reply__reply-btn text-blue"
        @click="isModal = true"
    >
      Ответить
    </button>
    <BaseModal
        :is-base-modal="isModal"
        :width="900"
        @close="closeModal"
    >
      <template v-slot:header>
        Ответить на отзыв
      </template>
      <div class="reviews__modal-content">

        <div class="reviews">
          <div class="reviews__author-block">
            <div class="reviews__author-avatar-block">
              <img :title="getImageName(review.author.photo)" v-if="review.author.photo" :src="`https://admin.tumar.com/${review.author.photo}`" :alt="getImageName(review.author.photo)">
              <img title="default-review-avatar" v-else src="/img/default-review-avatar.jpg" alt="default-review-avatar">
            </div>
            <div class="reviews__author-data">
              <div class="reviews__author-full-name">{{ review.author.full_name }}</div>
            </div>
          </div>

          <div class="reviews__comment">
            <p class="reviews__comment-text">
              {{ review.message }}
            </p>
          </div>

          <div class="reviews__date">
            {{ review.created_at }}
          </div>
        </div>
        <div class="reviews__modal-replies">
          <div
              v-for="reply in replies"
              :key="reply.id"
              class="reviews__modal-reply-message"
          >
            <div class="reviews__author-block">
              <div class="reviews__author-avatar-block">
                <img :title="getImageName(reply.author.photo)" v-if="reply?.author?.photo" :src="`https://admin.tumar.com/${reply?.author?.photo}`" :alt="getImageName(reply.author.photo)">
                <img title="default-review-avatar" v-else src="/img/default-review-avatar.jpg" alt="default-review-avatar">
              </div>
              <div class="reviews__author-data">
                <div class="reviews__author-item">...</div>
              </div>
            </div>

            <div class="reviews__comment">
              <p class="reviews__comment-text">
                {{ reply.message }}
              </p>
            </div>

            <div class="reviews__date">
              {{ reply.created_at }}
            </div>

            <div
                class="reviews__date"
                @click="deleteReply(reply.id)"
            >
              Delete
            </div>
          </div>
        </div>

        <textarea
            v-model="reply"
            class="reviews__textarea"
        ></textarea>
      </div>
      <template v-slot:footer>
        <div class="reviews__reply">
          <button
              class="reviews__reply-btn"
              @click="createReply"
          >
            Ответить
          </button>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import axios from '@/core/services/api.service'

import BaseModal from "@/UI/BaseModal";
import { getImageName } from "@/utils/utils";

export default {
  name: "ReviewReply",
  components: {
    BaseModal
  },
  props: {
    review: Object,
    replies: Array
  },
  data () {
    return {
      isModal: false,
      reply: '',
    }
  },
  methods: {
    closeModal () {
      this.isModal = false
      this.clearData()
    },
    clearData () {
      this.reply = ''
    },
    deleteReply (id) {
      axios.delete(`/specialgoods/review/reply/delete/${id}`)
          .then(() => {
            this.$emit('update')
          })
    },
    createReply () {
      const payload = {
        artisan_review_id: this.review.id,
        message: this.reply
      }

      axios.post('/specialgoods/review/create/reply', payload)
          .then((res) => {
            this.reply = ''
            this.$emit('update')
          })
    },
  }
}
</script>

<style scoped lang="scss">
.reviews {
  display: grid;
  grid-template-columns: 200px 1fr 100px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  width: 100%;

  &__modal-content {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: flex-end;
  }
  &__modal-reply-message {
    width: 90%;
    background: #E4F1E3;
    display: grid;
    grid-template-columns: 200px 1fr 100px;
    border-bottom: 1px solid #ccc;
    padding: 10px;
  }
  &__modal-replies {
    display: flex;
    row-gap: 10px;
    width: 100%;
    align-items: flex-end;
    flex-direction: column;
    height: 350px;
    overflow-y: auto;
  }
  &__date {
    text-align: center;
  }
  &__reply {
    display: flex;
    align-items: center;
    width: 100%;

    &-btn {
      margin-left: auto;
      background: #0a58ca;
      padding: 5px 10px;
      color: #fff;
    }
  }
  &__comment {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    row-gap: 10px;

    &-text {
      margin: 0;
      padding: 0;
    }


    &-btn-wrapper {
      display: flex;
      column-gap: 20px;
    }
    &-btn {
      font-size: 15px;
      font-weight: 600;
    }
  }

  &__author {
    &-block {
      display: flex;
      column-gap: 10px;
    }
    &-avatar-block {
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
      background: #0a58ca;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-data {
      width: 100%;
      font-size: 16px;
    }
  }
  &__textarea {
    border: 1px solid #F6F6F6;
    background: #F6F6F6;
    outline: none;
    width: 100%;
    height: 130px;
    padding: 17px 22px;
  }
  &__reply {
    display: flex;
    align-items: center;
    width: 100%;

    &-btn {
      margin-left: auto;
      background: #0a58ca;
      padding: 5px 10px;
      color: #fff;
    }
  }
}
</style>
