<template>
  <div>
    <div
        @click="isModal = true"
    >
      <slot />
    </div>
    <base-modal
        :is-base-modal="isModal"
        :width="600"
        @close="closeModal"
    >
      <v-card flat>
        <slot name="content" />
      </v-card>
    </base-modal>
  </div>
</template>

<script>
import BaseModal from "@/UI/BaseModal";

export default {
  name: "OverView",
  components: {
    BaseModal
  },
  data () {
    return {
      isModal: false,
    }
  },
  methods: {
    closeModal () {
      this.isModal = false
    }
  }
}
</script>

<style scoped lang="scss">

</style>
