<template>
  <v-card flat class="mt-1 pa-2">
    <div class="reviews__filter-block">
      <span>Filter by status: &nbsp;</span>
      <select v-model="currentReviewType">
        <option disabled value="">Please select one</option>
        <option>Approved</option>
        <option>Pending</option>
      </select>
    </div>
    <div class="reviews__title-wrapper">
      <div class="reviews__title">Автор</div>
      <div class="reviews__title">Комментарий</div>
      <div class="reviews__title">Дата</div>
    </div>
    <div
        v-for="review in reviewList"
        :key="review.id"
        class="reviews"
    >
      <div class="reviews__author-block">
        <div class="reviews__author-avatar-block">
          <img v-if="review.author.photo" :src="`https://admin.tumar.com/${review.author.photo}`" :alt="getImageName(review.author.photo)" :title="getImageName(review.author.photo)" 
          >
          <img v-else src="/img/default-review-avatar.jpg" alt="default-review-avatar" title="default-review-avatar" >
        </div>
        <div class="reviews__author-data">
          <div class="reviews__author-full-name">...</div>
          <div class="reviews__author-item">
            <rating
                :ratingValue="review.rating"
            />
          </div>
        </div>
      </div>

      <div class="reviews__comment">
        <p
            v-if="review.photo"
            class="reviews__comment-text"
        >
          <over-view>
            <template>
              <span class="reviews__product-img">
                <img :src="`https://admin.tumar.com/storage/${review.photo}`" :alt="getImageName(review.photo)" :title="getImageName(review.photo)" 
                >
              </span>
            </template>
            <template v-slot:content>
              <span class="reviews__overview-block">
                <span class="reviews__overview-product-image">
                  <img :src="`https://admin.tumar.com/storage/${review.photo}`" :alt="getImageName(review.photo)" :title="getImageName(review.photo)"/>
                </span>
              </span>
            </template>
          </over-view>
          {{ review.message }}
        </p>
        <div class="reviews__comment-btn-wrapper">
          <button
              v-if="review.status === 'pending'"
              class="reviews__comment-approve reviews__comment-btn text-green"
              @click="approveReview(review.id)"
          >
            Одобрить
          </button>

          <review-reply
              :review="review"
              :replies="review.replies"
              @update="getReviews"
          >
          </review-reply>
          <button
              class="reviews__comment-delete reviews__comment-btn text-red"
              @click="deleteReview(review.id)"
          >
            Удалить
          </button>
        </div>
      </div>

      <div class="reviews__date">
        {{ review.created_at }}
      </div>
    </div>

    <div class="reviews__pagination">
      <button
          :class="[page === 1 ? 'reviews__disabled-btn':'reviews__pagination-prev']"
          @click="prev"
      >
        Previous
      </button>
      <div
          class="reviews__pagination-current-page"
      >
        {{ page }}
      </div>
      <button
          :class="[page < countOfPages ? 'reviews__pagination-next':'reviews__disabled-btn']"
          @click="next"
      >
        Next
      </button>
    </div>

  </v-card>
</template>

<script>
import axios from '@/core/services/api.service'
import { getImageName } from '@/utils/utils'

import Rating from "@/UI/Rating";
import ReviewReply from "@/components/ReviewReply";
import OverView from "@/components/OverView";

import { getDataFromLocalStorage } from "@/utils/utils";

export default {
  name: "Reviews",
  components: {
    Rating,
    ReviewReply,
    OverView
  },
  data () {
    return {
      artisanId: null,
      page: 1,
      totalPage: 1,
      pagesPerPage: 20,
      currentReviewType: 'Approved',
      pendingReviews: [],
      approvedReviews: []
    }
  },
  computed: {
    reviewList () {
      if (this.currentReviewType === 'Approved') {
        return this.approvedReviews
      } else {
        return this.pendingReviews
      }
    },
    countOfPages () {
      return Math.ceil(this.totalPage / this.pagesPerPage)
    }
  },
  watch: {
    currentReviewType: {
      handler () {
        this.page = 1

        if (this.currentReviewType === 'Approved') {
          this.getApprovedReviews(this.artisanId)
        } else {
          this.getPendingReviews(this.artisanId)
        }
      },
    },
    page () {
      if (this.currentReviewType === 'Approved') {
        this.getApprovedReviews(this.artisanId)
      } else {
        this.getPendingReviews(this.artisanId)
      }
    }
  },
  mounted() {
    this.artisanId = getDataFromLocalStorage('user_id')
    this.getApprovedReviews(this.artisanId)
  },
  methods: {
    getImageName,

    prev () {
      if (this.page > 1) {
        this.page -= 1
      }
    },
    next () {
      if (this.page < this.countOfPages) {
        this.page += 1
      }
    },
    approveReview (reviewId) {
      let payload = {
        status:"approved"
      }

      axios.patch(`/specialgoods/review/${reviewId}/status`, payload)
      .then((res) => {
        this.getPendingReviews(this.artisanId)
      })
    },
    getPendingReviews(craftsmanId) {
      axios.get(`/specialgoods/review/pending/${craftsmanId}?page=${this.page}`)
      .then((res) => {
        this.pendingReviews = res.data.data
        this.totalPage = res.data.meta.total
        this.pagesPerPage = res.data.meta.per_page
      })
    },
    getApprovedReviews(craftsmanId) {
      axios.get(`/specialgoods/review/approved/${craftsmanId}?page=${this.page}`)
        .then((res) => {
          this.approvedReviews = res.data.data
          this.totalPage = res.data.meta.total
          this.pagesPerPage = res.data.meta.per_page
        })
    },
    deleteReview (reviewId) {
      axios.delete(`/specialgoods/review/delete/${reviewId}`)
      .then(() => {
        this.getPendingReviews(this.artisanId)
        this.getApprovedReviews(this.artisanId)
      })
    },
    getReviews() {
      if (this.currentReviewType === 'Approved') {
        this.getApprovedReviews(this.artisanId)
      } else {
        this.getPendingReviews(this.artisanId)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.reviews {
  display: grid;
  grid-template-columns: 200px 1fr 100px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 20px;

  &__overview-block {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__overview-product-image {
    width: 500px;
    height: auto;
    overflow: hidden;
    display: block;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__disabled-btn {
    background: #ccc;
    border: 1px solid #ccc;
    padding: 5px 10px;
    transition: all .3s;
    min-width: 80px;
    color: #b9afaf;

    &:hover {
      cursor: not-allowed;
    }
  }

  &__modal-content {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    align-items: flex-end;
  }
  &__modal-reply-message {
    max-width: 90%;
    background: #E4F1E3;
    padding: 10px;
  }

  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;

    &-prev,&-next {
      border: 1px solid #ccc;
      padding: 5px 10px;
      transition: all .3s;
      min-width: 80px;

      &:hover {
        background: #cce5f6;
      }
    }
    &-current-page {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 10px;
      width: 40px;
      font-size: 20px;
    }
  }

  &__filter-block {
    margin: 20px 0 20px 20px;
  }
  &__textarea {
    border: 1px solid #F6F6F6;
    background: #F6F6F6;
    outline: none;
    width: 100%;
    height: 130px;
    padding: 17px 22px;
  }
  &__reply {
    display: flex;
    align-items: center;
    width: 100%;

    &-btn {
      margin-left: auto;
      background: #0a58ca;
      padding: 5px 10px;
      color: #fff;
    }
  }
  &__title-wrapper {
    display: grid;
    grid-template-columns: 200px 1fr 100px;
    height: 40px;
  }
  &__title {
    text-align: center;
    font-weight: 700;
  }

  &__date {
    text-align: center;
  }

  &__product-img {
    width: 40px !important;
    height: 40px !important;
    overflow: hidden;
    border-radius: 50%;
    display: block;
    float: left;
    margin: 0 5px 5px 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &:hover {
        cursor: pointer;
      }
    }
  }
  &__comment {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    row-gap: 10px;

    &-text {
      margin: 0;
      padding: 0;
    }


    &-btn-wrapper {
      display: flex;
      column-gap: 20px;
    }
    &-btn {
      font-size: 15px;
      font-weight: 600;
    }
  }

  &__author {
    &-block {
      display: flex;
      column-gap: 10px;
    }
    &-avatar-block {
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
      background: #0a58ca;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-data {
      width: 100%;
      font-size: 16px;
    }
  }
}
</style>
